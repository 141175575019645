import axios from "axios";
import { toast } from "react-toastify";

const api_public = axios.create({

  //baseURL: "https://cloud-api.senneliquor.com.br/api", // Servidor Teste
  // baseURL: "http://localhost:8000/api", // Local
  // baseURL: "https://api-node-hom.proudground-c683b123.brazilsouth.azurecontainerapps.io/api", 
  // baseURL: "https://api-hom.senneliquor.com.br/api",
  // baseURL: "http://SENNEDESK04001:8000/api", // Local
  baseURL: "https://api-hom.senneliquor.com.br/api",
  //baseURL: process.env.baseURL,
  timeout: 1000 * 30,
});

api_public.interceptors.response.use(
  (response) => Promise.resolve(response),

  (error) => {

    if (!error.response) {
      toast.error("Falha na conexão com o servidor");
      return Promise.reject(error);
    }

    if (error.response?.status >= 400 && error.response.status <= 500) {
      // window.location.pathname = "/";
      toast.error(error.response.data);
    }

    if (error.response?.status >= 500 || error?.response?.status === 0) {
      toast.error('Falha ao processar a solicitação');
    }

    return Promise.reject(error);
  },
);


export default api_public;
