import React from "react";
import api from "../services/api";
import axios from "axios";
import { toast } from "react-toastify";

export const UsersContext = React.createContext({});

export const UsersProvider = ({ children }) => {
  async function getUsers(params) {
    const response = await api.get("/list/users", { params });
    return response?.data;
  }

  const getLogs = async (userId) => {
    const response = await api.get(`/list/logs/user/${userId}`);
    return response.data;
  };

  const getLogsAll = async (params) => {
    const response = await api.get(`/list/logs/users`, { params });
    return response.data;
  };

  const getUser = async (userId) => {
    const response = await api.get(`/show/user/${userId}`);
    return response.data;
  };

  const getUserDoctor = async (userCRM, userUF) => {
    try {
      const response = await api.get(`/list/doctors?crm=${userCRM}&uf=${userUF}`);

      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const approveDoctor = async (id_Doctor, data) => {
    try {
      const response = await api.post(
        `/approve/user/doctor/${id_Doctor}`,
        data,
      );

      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const approveUser = async (id, data) => {
    try {
      const response = await api.post(`/approve/user/${id}`, data);
     
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const getUsersByGroup = async (Id, params) => {
    const response = await api.get(`/list/group/user/${Id}`, { params });
    return response.data;
  };

  const getUsersByHospitalsUser = async (params) => {
    const response = await api.get(`/users/list`, { params });

    console.log(response.data);

    return response.data;
  };

  const getUsersByHospital = async (Id, params) => {
    const response = await api.get(`hospital/user/list/${Id}`, { params });

    return response;
  };

  const createUser = async (data) => {
    try {
      const response = await api.post(`/user/create`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return error;
    }
  };

  const editUser = async (data, userId) => {
    try {
      const response = await api.put(`/edit/user/${userId}`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }

      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const editDoctor = async (data, userId) => {
    try {
      const response = await api.post(`/edit/user/doctor/${userId}`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }

      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const editUserImage = async (data) => {
    const response = await api.post(`/upload/user/image`, data);
    return response;
  };

  const deleteUser = async (userId) => {
    try {
      const response = await api.delete(`/delete/user/${userId}`);
      // toast.warning("Erro ao Editar o usuário.");

      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const inactiveUser = async (userId, data) => {
    const response = await api.put(`inactivate/user/${userId}`, data);
    return response;
  };
  const forgotPassword = async (data) => {
    try {
      const response = await axios.post(`
      https://api-prod.senneliquor.com.br/api/password/forgot`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <UsersContext.Provider
      value={{
        getUser,
        getUsers,
        deleteUser,
        getLogs,
        editDoctor,
        editUser,
        editUserImage,
        createUser,
        getUsersByGroup,
        getLogsAll,
        inactiveUser,
        getUsersByHospital,
        getUserDoctor,
        approveDoctor,
        approveUser,
        forgotPassword,
        getUsersByHospitalsUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
