import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Text,
  Switch,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { set, useForm } from "react-hook-form";
import * as yup from "yup";

import { PrimaryResultsButton } from "../../components/Buttons";
import InternalHeader from "../../components/InternalHeader";
// import Breadcrumb from "../../components/Breadcrumb";
import TextSelectHospital from "../../components/TextSelectHospital";
import TextInput from "../../components/TextInput";
import SideBar from "../../components/SideBar";
import Loading from "../../components/Loading";

import { useUsers, useAuth, useHospitals } from "../../hooks";

import Avatar from "../../images/avatar.svg";

export default function AddUser() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [uuid, setUUID] = useState([]);
  const [selectedUUID, setSelectedUUID] = useState([]);
  const [codProcedencia, setCodProcedencia] = useState([]);

  const [profileImage, setProfileImage] = useState(null);
  const [hospitalsList, setHospitalsList] = useState();
  const [photoBase64, setPhotoBase64] = useState();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [error, setError] = useState();

  const { createUser, editUserImage } = useUsers();
  const { listHospital, getHospitals } = useHospitals();

  const [uuidUser, setUUIDUser] = useState(
    // localStorage.getItem("hospital") ? localStorage.getItem("hospital") : null
  );

  const { getHospitalsList, getUserHospitalList } = useHospitals();


  const [hospitalName, setHospitalName] = useState(localStorage.getItem("hospitalName"));

  const [permissions, setPermissions] = useState();

  const [hospitalsListNoPagination, setHospitalsListNoPagination] = useState(
    [],
  );


  const schema = yup.object().shape({
    name: yup.string().required("O campo nome é obrigatório"),
    email: yup.string().email().required("O campo email é obrigatório"),
    cpf: yup.string().required("O campo CPF é obrigatório"),
    cellphone: yup.string().required("O campo telefone é obrigatório"),
    hospitals: yup.string().required("O campo hospitais é obrigatório"),
    permiteContato: yup.boolean().required("O campo permite contato é obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // function getNames(objs, uuids) {
  //   return objs
  //     .filter((obj) => uuids.includes(obj.uuid))
  //     .map((obj) => obj.name);
  // }

  // function getProcedencia(objs, uuids) {
  //   return objs
  //     .filter((obj) => uuids.includes(obj.uuid))
  //     .map((obj) => obj.id);
  // }

  function getNames(objs, uuids) {
    return objs
      .filter((obj) => uuids.includes(obj.uuid))
      .map((obj) => obj.name);
  }

  function getProcedencia(objs, uuids) {
    return objs
      .filter((obj) => uuids.includes(obj.uuid))
      .map((obj) => obj.id_hospital);
  }



  function removeProcSelected(index) {
    uuid.splice(index, 1);
    selectedUUID.splice(index, 1);
    setUUID(uuid);
    setSelectedUUID(getNames(hospitalsList, uuid));
    setCodProcedencia(getProcedencia(hospitalsList, uuid));
  }

  async function getHospitalList() {

    const hospital = await getHospitalsList();

    const hospitalList = hospital.map((item) => ({
      uuid: item.uuid,
      name: item.name,
    }));
    setHospitalsListNoPagination(hospitalList);

    setHospitalsList(hospitalList);
  }

  function listProc(value) {
    try {
      if (!value) {
        return;
      }

      if (uuid.includes(value)) {
        return;
      }

      // uuid.push(value);

      setUUID(value);
      setSelectedUUID(getNames(hospitalsList, value));
      setCodProcedencia(getProcedencia(hospitalsList, value));
    } catch (error) {
      console.log(error);
    }
  }

  async function getHospital() {
    try {
      const role_id = JSON.parse(localStorage.getItem("User_senne"));

      if (role_id?.role_id === 1) {
        const results = await getHospitals();
        setHospitalsList(results?.Hospitals);
      } else {
        const results = await getUserHospitalList();
        setHospitalsList(results?.hospitals);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }
  }

  async function uploadImage() {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", profileImage);
      formData.append("id_user", userId);
      await editUserImage(formData);
      navigate(`/users`);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function getBase64() {
    try {
      let document = "";
      let reader = new FileReader();
      reader.readAsDataURL(profileImage);
      reader.onloadend = function (e) {
        document = reader.result;
        setPhotoBase64(document);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    } catch (error) {
      console.error(error);
      setError(error);
    }
  }

  const submit = async (values) => {
    try {
      setLoading(true);
      const permissions = [];
      let hospital_id = ''
      for (let i = 0; i < hospitalsList.length; i += 1) {
        if (values.hospitals === hospitalsList[i].uuid) {
          console.log('OIIII', hospitalsList[i])
          hospital_id = hospitalsList[i].id ?? hospitalsList[i].id_hospital
        }
      }
      // console.log('aaaaaa:', getNames(hospitalsList, codProcedencia))
      // console.log('ahahahah:',getProcedencia(hospitalsList, codProcedencia))
      // console.log('codProcedencia:', codProcedencia)
      if (values.administrator) {
        permissions.push(1);
      }
      if (values.scheduling) {
        permissions.push(2);
      }
      if (values.results) {
        permissions.push(3);
      }
      const values_api = {
        crm: "",
        ...values,
        hospitals: hospital_id,
        permissions: permissions,
      };
      console.log(values_api)

      const result = await createUser({
        cellphone: values_api.cellphone,
        cpf: values_api.cpf,
        email: values_api.email,
        name: values_api.name,
        hospitals: hospital_id,
        permissions: permissions,
        permiteContato: values_api.permiteContato,
      });
      setUserId(result?.data?.data?.id);

      if (result.status === 200) {

        navigate(`/users`);

      }


    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);

      // navigate(`/users`);
    }
  };

  // async function getUserHospitalsList() {

  //   const response = await getUserHospitalList();
  //   const { hospitals } = response;
  //   return hospitals;
  // }

  // async function getUserPermission( defaultHospital ) {

  //   const hospitals = await getUserHospitalsList();
  //   const { permissions } = hospitals.find((hospital) => hospital.uuid === defaultHospital);

  //   // console.log("permissions", permissions);
  //   setPermissions(permissions);

  // }

  // useEffect(() => {
  //   getHospital();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (userId) {
      uploadImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (profileImage) {
      getBase64();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImage]);

  useEffect(() => {
    getHospital();
    if (uuidUser === undefined && localStorage.getItem("hospital")) {
      localStorage.getItem("hospital").length > 10 ? setUUIDUser(localStorage.getItem("hospital")) : setUUIDUser(null);
      localStorage.getItem("hospitalName") ? setHospitalName(localStorage.getItem("hospitalName")) : setHospitalName(null);
      // getUserPermission(localStorage.getItem("hospital"));
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return (
      <div>
        <p>Erro encontrado!</p>
      </div>
    );
  }

  return (
    <Flex width="100vw" minHeight="100vh" backgroundColor="var(--cinza-bg)">
      {loading && <Loading />}
      <Flex width="220px" id="sidebar" className="sidebar">
        <SideBar permissions={permissions} />
      </Flex>
      <Box p="44px 88px 22px 30px" w="calc(100% - 200px)">
        <InternalHeader
          user={user}
          // showHospital={true}
          uuidUser={uuidUser ? uuidUser : localStorage.getItem("hospital")}
          hospitalName={hospitalName ? hospitalName : localStorage.getItem("hospitalName")}
        />
        {/* <Breadcrumb
          {}
          // page={
          //   <>
          //     <Link to="/users">Gerenciamento de Usuários</Link> / Adicionar
          //     membro
          //   </>
          
          // pageTitle="Adicionar Usuário Hospital"
        /> */}

        <Box
          mt="60px"
          border="1px solid var(--cinza-card)"
          borderRadius="10px"
          p="46px 60px"
          backgroundColor="white"
        >
          <Flex alignContent="center" alignItems="center" gap="15px" mb="16px">
            <img
              width="150px"
              src={profileImage ? photoBase64 : Avatar}
              alt="foto"
            />

            <input
              type="file"
              name="profileImage"
              accept="image/png, image/jpeg"
              onChange={(event) => setProfileImage(event.target.files[0])}
              style={{
                position: "absolute",
                width: "100%",
                opacity: 0,
                cursor: "pointer",
              }}
            ></input>

            <Text as="strong" fontSize="1.4rem">
              Adicionar foto de perfil
            </Text>
          </Flex>

          <form onSubmit={handleSubmit(submit)}>
            <Box pr="39px">
              <Flex justifyContent="space-around" gap="100px">
                <TextInput
                  isError={errors.name}
                  register={{ ...register("name") }}
                  labelDescription="Nome Completo"
                  InputPlaceholder="Nome Completo"
                  name="name"
                ></TextInput>

                <TextInput
                  isError={errors.cpf}
                  register={{ ...register("cpf") }}
                  maskInput="***.***.***-**"
                  labelDescription="CPF"
                  InputPlaceholder="123.456.789-12"
                  name="cpf"
                />
              </Flex>
              <Flex justifyContent="space-around" gap="100px">
                <TextInput
                  isError={errors.cellphone}
                  register={{ ...register("cellphone") }}
                  maskInput="(**) *****-****"
                  labelDescription="Qual seu telefone"
                  InputPlaceholder="(99) 99999-9999"
                  name="cellphone"
                />

                <TextInput
                  isError={errors.email}
                  register={{ ...register("email") }}
                  labelDescription="Email"
                  InputPlaceholder="email@email.com.br"
                  name="email"
                ></TextInput>
              </Flex>
              <Flex gap="100px">
                <Flex w="100%" flexDirection="column">
                  <Flex w="100%" mt="10px" flexDirection="column">
                    <TextSelectHospital
                      isError={errors.hospitals}
                      register={{ ...register("hospitals") }}
                      labelDescription="Seleciona as unidades"
                      mt="7px"
                      name="hospitals"
                      titleOption="Unidades"
                      w="100%"
                      options={hospitalsList}
                      onChange={(e) => {
                        listProc(e.target.value);
                        setCodProcedencia(e.target.value)
                      }}
                    />
                  </Flex>
                  {/* <Flex w="100%" mt="20px" flexDirection="column">
                    <TextSelectHospital
                      isError={errors.permiteContato}
                      register={{ ...register("permiteContato") }}
                      labelDescription="Permite contato para resultado crítico"
                      mt="7px"
                      name="permiteContato"
                      titleOption="Seleciona a opção... "
                      w="100%"
                      options={[{id: 1, name: 'Sim'}, {id: 2, name: 'Não'}]}
                    />
                  </Flex> */}
                </Flex>

                {/* <Wrap spacing={4} mt="10px" w="294px" maxW="294px">
                    {selectedUUID.map((item, index) => (
                      <WrapItem>
                        <Tag
                          size="md"
                          key={index}
                          borderRadius="full"
                          variant="solid"
                          colorScheme="green"
                          maxW="180px"
                        >
                          <TagLabel>{item}</TagLabel>
                          <TagCloseButton
                            onClick={() => {
                              removeProcSelected(index);
                            }}
                          />
                        </Tag>
                      </WrapItem>
                    ))}
                  </Wrap> */}

                <Flex flexDirection="column" w="100%" gap="20px">
                  <Text as="strong">Permissões</Text>
                  <Flex gap="40px">
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Switch
                        size="md"
                        colorScheme="orange"
                        name="scheduling"
                        id="scheduling"
                        {...register("scheduling")}
                      />
                      <Text w="200px">Agendamento</Text>
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Switch
                        size="md"
                        colorScheme="orange"
                        name="results"
                        id="results"
                        {...register("results")}
                      />
                      <Text>Resultados</Text>
                    </Flex>
                  </Flex>
                  <Flex gap="40px">
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Switch
                        size="md"
                        colorScheme="orange"
                        name="administrator"
                        id="administrator"
                        {...register("administrator")}
                      />
                      <Text w="200px">Administrador</Text>
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Switch
                        size="md"
                        colorScheme="orange"
                        name="permiteContato"
                        id="permiteContato"
                        {...register("permiteContato")}
                      />
                      <Text>Ponto Focal para Resultados Críticos</Text>
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    ></Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex justifyContent="end" mt="20px">
                <Box w="121px" h="30px">
                  <PrimaryResultsButton type="submit">
                    Continuar
                  </PrimaryResultsButton>
                </Box>
              </Flex>
            </Box>
          </form>
        </Box>
      </Box>
    </Flex>
  );
}
